import React from "react";

// reactstrap components
import { Card, CardBody, Container, Row, Col } from "reactstrap";

// Core Components

function Feature7() {
  return (
    <>
      <div className="section features-7 bg-secondary">
        <Container>
          <Row className="margin-top-80">
            <Col className="text-center mx-auto" md="8">
              <h3 className="display-3 margin-top-80">Endorsements</h3>
              <p className="lead">
                <strong>The campaign is proudly endorsed by</strong>
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col lg="12">
              <div class="card-deck">
                <Row className="row-grid">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-center">
                      <img
                        alt="..."
                        className="lg-5 img-fluid"
                        src={require("assets/img/ill/AFLCIO.PNG")}
                        width="70%"
                      ></img>
                      <h6 className="text-primary text-uppercase">
                        <center>AFL-CIO</center>
                      </h6>
                    </CardBody>
                  </Card>
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-center">
                      <img
                        alt="..."
                        className="lg-5 margin-top-110"
                        src={require("assets/img/ill/CWPC.PNG")}
                        width="70%"
                      ></img>
                      <h6 className="text-primary text-uppercase">
                        <center>Cincinnati Women's Political Caucus</center>
                      </h6>
                    </CardBody>
                  </Card>
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-center">
                      <img
                        alt="..."
                        className="lg-5"
                        src={require("assets/img/ill/CommunityActionProgram.PNG")}
                        width="70%"
                      ></img>
                      <h6 className="text-primary text-uppercase">
                        <center>Community Action Program</center>
                      </h6>
                    </CardBody>
                  </Card>
                </Row>
                <Row>
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-center">
                      <img
                        alt="..."
                        className="lg-5"
                        src={require("assets/img/ill/FOP.JPG")}
                        width="60%"
                      ></img>
                      <h6 className="text-primary text-uppercase">
                        <center>Fraternal Order of Police</center>
                      </h6>
                    </CardBody>
                  </Card>
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-center">
                      {" "}
                      <img
                        alt="..."
                        className="lg-5"
                        src={require("assets/img/ill/PPAO-Endorsements-FB_1200x630_Tallent.jpg")}
                        width="100%"
                      ></img>
                      <h6 className="text-primary text-uppercase">
                        <center>Planned Parenthood</center>
                      </h6>
                    </CardBody>
                  </Card>
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-center">
                      <CardBody className="py-5 text-center">
                        {" "}
                        <img
                          alt="..."
                          className="lg-5"
                          src={require("assets/img/ill/VoteProChoice.png")}
                          width="60%"
                        ></img>
                        <h6 className="text-primary text-uppercase">
                          <center>Vote Pro Choice</center>
                        </h6>
                      </CardBody>
                    </CardBody>
                  </Card>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Feature7;
